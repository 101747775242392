import { render, staticRenderFns } from "./company.vue?vue&type=template&id=02ba9879&scoped=true"
import script from "./company.vue?vue&type=script&setup=true&lang=js"
export * from "./company.vue?vue&type=script&setup=true&lang=js"
import style0 from "./company.vue?vue&type=style&index=0&id=02ba9879&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@6.26.3_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prett_mslvjkx5fqxq5hwkswdqi36zjm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02ba9879",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UnauthorizedIcon: require('/vercel/path0/components/UnauthorizedIcon.vue').default,StyledDialog: require('/vercel/path0/components/StyledDialog.vue').default})
